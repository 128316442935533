export const hops = [
  {
    lotId: '18AMT90220',
    pdf: 'Flavor_18AMT90220.pdf',
    grapeFruit: 1,
    citrus: 1,
    'stone-fruit': 2,
    herbacious: 1,
    'sweet-aromatic': 0.5
  },
  {
    lotId: '18AMT90221',
    pdf: 'Flavor_18AMT90221.pdf',
    grapeFruit: 1,
    citrus: 1,
    'stone-fruit': 2,
    woody: 0.5,
    herbacious: 1,
    'sweet-aromatic': 0.5
  },
  {
    lotId: '18AMT90222',
    pdf: 'Flavor_18AMT90222.pdf',
    grapeFruit: 2,
    citrus: 1,
    'stone-fruit': 2,
    herbacious: 1,
    'sweet-aromatic': 1
  },
  {
    lotId: '18AMT90223',
    pdf: 'Flavor_18AMT90223.pdf',
    grapeFruit: 0.5,
    citrus: 1,
    'stone-fruit': 2,
    woody: 1,
    herbacious: 1,
    'sweet-aromatic': 0.5,
    onion: 1
  },
  {
    lotId: '18AMT90224',
    pdf: 'Flavor_18AMT90224.pdf',
    grapeFruit: 1,
    citrus: 1,
    'stone-fruit': 2,
    tropical: 2,
    floral: 0.5,
    'sweet-aromatic': 1
  },
  {
    lotId: '18AMT90225',
    pdf: 'Flavor_18AMT90225.pdf',
    grapeFruit: 2,
    citrus: 1,
    'stone-fruit': 1,
    herbacious: 0.5,
    onion: 2,
    cheese: 1
  },
  {
    lotId: '18AMT90227',
    pdf: 'Flavor_18AMT90227.pdf',
    grapeFruit: 1.5,
    citrus: 1,
    'stone-fruit': 1,
    woody: 0.5,
    floral: 1,
    herbacious: 0.5,
    'sweet-aromatic': 1
  },
  {
    lotId: '18AMT90228',
    pdf: 'Flavor_18AMT90228.pdf',
    grapeFruit: 2,
    citrus: 1.5,
    'stone-fruit': 1,
    grassy: 0.5,
    floral: 1,
    herbacious: 1,
    onion: 0.5
  },
  {
    lotId: '18AMT90229',
    pdf: 'Flavor_18AMT90229.pdf',
    grapeFruit: 1.5,
    citrus: 2,
    'stone-fruit': 0.5,
    tropical: 1,
    woody: 0.5,
    floral: 1,
    herbacious: 1
  },
  {
    lotId: '18AMT90230',
    pdf: 'Flavor_18AMT90230.pdf',
    grapeFruit: 2,
    citrus: 1.5,
    'stone-fruit': 1,
    tropical: 0.5,
    grassy: 0.5,
    woody: 0.5,
    floral: 0.5,
    herbacious: 1,
    'sweet-aromatic': 1
  },
  {
    lotId: '18-296',
    pdf: 'Flavor-German-Amarillo-T45-VA18000296.pdf',
    grapeFruit: 1.5,
    citrus: 2,
    'stone-fruit': 2,
    tropical: 2.5,
    floral: 1.5,
    herbacious: 1
  },
  {
    lotId: '18-297',
    pdf: 'Flavor-German-Amarillo-T45-VA18000297.pdf',
    grapeFruit: 1.5,
    citrus: 1.5,
    'stone-fruit': 1,
    grassy: 1,
    woody: 1,
    floral: 1.5,
    herbacious: 1.5,
    'sweet-aromatic': 2,
    lemon: 2
  },
  {
    lotId: '18-298',
    pdf: 'Flavor-German-Amarillo-T45-VA18000298.pdf',
    grapeFruit: 2,
    citrus: 1.5,
    'stone-fruit': 2.5,
    tropical: 1,
    woody: 0.5,
    floral: 1.5,
    herbacious: 1,
    onion: 1,
    lemon: 0.5
  },
  {
    lotId: '18-301',
    pdf: 'Flavor-German-Amarillo-T45-VA18000301.pdf',
    grapeFruit: 2.5,
    citrus: 2,
    'stone-fruit': 1.5,
    woody: 1,
    floral: 1.5,
    herbacious: 1.5,
    'sweet-aromatic': 0.5,
    onion: 1
  },
  {
    lotId: '18-302',
    pdf: 'Flavor-German-Amarillo-T45-VA18000302.pdf',
    grapeFruit: 2,
    citrus: 2,
    'stone-fruit': 2,
    tropical: 0.5,
    woody: 1.5,
    floral: 1.5,
    herbacious: 1,
    'sweet-aromatic': 1
  },
  {
    lotId: '18-303',
    pdf: 'Flavor-German-Amarillo-T45-VA18000303.pdf',
    grapeFruit: 1.5,
    citrus: 1.5,
    'stone-fruit': 1,
    grassy: 1,
    floral: 1.5,
    herbacious: 1.5,
    'sweet-aromatic': 1.5,
    lemon: 2
  },
  {
    lotId: '18AMT90217',
    pdf: 'Flavor_18AMT90217.pdf',
    grapeFruit: 3,
    'stone-fruit': 1,
    herbacious: 1,
    'sweet-aromatic': 1
  },
  {
    lotId: '18AMT90218',
    pdf: 'Flavor_18AMT90218.pdf',
    grapeFruit: 1,
    citrus: 1,
    'stone-fruit': 2,
    woody: 0.5,
    herbacious: 1,
    'sweeet-aromatic': 1
  },
  {
    lotId: '18AMT90219',
    pdf: 'Flavor_18AMT90219.pdf',
    grapeFruit: 1.5,
    citrus: 1,
    'stone-fruit': 1.5,
    woody: 1,
    herbacious: 1,
    onion: 1,
    cheese: 0.5
  },
  {
    lotId: '18-295',
    pdf: 'Flavor-German-Amarillo-T45-VA18000295.pdf',
    grapeFruit: 2,
    citrus: 2,
    'stone-fruit': 2.5,
    tropical: 1.5,
    floral: 1.5,
    herbacious: 1,
    'sweet-aromatic': 1
  }
];
