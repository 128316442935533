import "../css/styles.css";

import * as React from "react";

import { StaticQuery, graphql } from "gatsby";

import BackgroundImage from "gatsby-background-image";
import HopPdfsList from "../components/HopPdfsList/HopPdfsList";
import { SEOPagesDescription } from "../utils/seo.util";
import Seo from "../components/SEO/Seo";
import { withLayout } from "../components/Layout";

const HopPdfPage = () => (
  <React.Fragment>
    <Seo {...SEOPagesDescription["hop-pdfs"]} />
  <StaticQuery
    query={graphql`
      query {
        desktop: file(
          relativePath: { eq: "Beer_1920x5760-NEW.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData
      const imageData = data.desktop.childImageSharp.fluid;
      return (
          <BackgroundImage
              Tag="section"
              fluid={imageData}
              backgroundColor={`#040e18`}
              style={{backgroundSize: "cover"}}
          >
              <HopPdfsList/>
          </BackgroundImage>
      );
    }}
  />
  </React.Fragment>

);

export default withLayout(HopPdfPage);
