import * as React from "react";
// @ts-ignore
import classes from "./HopPdfsList.module.css"
import { Document, Page } from "react-pdf/dist/entry.webpack";
import { hops } from "../../hops/hops";

const HopPdfsList = () => {
  return (
    <div className={classes.hopPdfListRoot}>
      <h1 className={classes.hopLotHeader}>Hop Lot ID Info</h1>
      <div className={classes.pdfsWrapper}>
        {hops &&
          hops.map(hop => (
            <div key={hop.lotId} className={classes.hopContainer}>
              <a
                href={`/pdfs/${hop.pdf}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.pdfLink}
              >
                <Document file={require(`../../../static/pdfs/${hop.pdf}`)}>
                  <Page
                    pageNumber={1}
                    className={classes.pdfPage}
                    width={160}
                  />
                </Document>
                <p className={classes.hopTitle}>Lot ID: {hop.lotId}</p>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HopPdfsList;
